<template>
  <win-block>
    <template #win-title-left>Проект</template>
    <template #win-title-right>
      <v-btn
          class="softvision"
          color="error"
          outlined
          x-small
          @click="dialog_delete=true"
      >
        Удалить
      </v-btn>
    </template>
    <v-card elevation="0">
      <v-card-text>
        <form>

          <v-text-field
              label="Название"
              v-model="form.name"
              :counter="30"
          />
          <v-text-field
              label="Краткое описание"
              v-model="form.longname"
              :counter="200"
          />
          <v-text-field
              label="Ссылка"
              readonly
              :value="link_project"
          />
          <v-checkbox
              v-model="form.private"
              label="Ограничить доступ"
              @change="()=>{}"
          ></v-checkbox>

          <v-textarea height="100%" v-model="form.description">
            <template v-slot:label>
              <div>
                Описание
              </div>
            </template>
          </v-textarea>

        </form>
      </v-card-text>

      <v-card-actions>

        <v-spacer></v-spacer>

        <v-btn
            class="softvision"
            color="primary"
            :loading="save_data"
            depressed
            @click="saveForm"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>



    <v-dialog v-model="dialog_delete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Удалить "{{ this.$store.state.project.name }}" ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_delete=false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteProject">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </win-block>
</template>


<script>

export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
  },
  data: () => ({
    dialog_delete:false,
    sparkline_loading: true,
    form: {private: true, description: '', name: '', longname: ''},
    link_project: '',
    save_data: false
  }),
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.link_project = this.$store.state.domen + '/home/' + this.$store.state.project.nameSys
      this.form.name = this.$store.state.project.name
      this.form.description = this.$store.state.project.description
      this.form.private = this.$store.state.project.private
      this.form.longname = this.$store.state.project.longname
    },
    saveForm() {
      this.save_data = true
      this.$axios.patch(this.$url.core_mix_projects + `${this.$store.state.project.id}/`, this.form)
          .then(response => {
            this.save_data = false
            this.$store.state.project = response.data
          })
    },
    deleteProject(){
      this.$axios.delete(this.$url.core_mix_projects + `${this.$store.state.project.id}/`)
          .then(response => {
            response
            // this.$router.push("/")
            // this.$bus.emit('reloadPage');
            // this.dialog_delete = false
            window.location.href = '/home'
          })
    }
  }
}
</script>
